<template>
  <main>
  	<div id="slide"></div>
  	<div class="campagne_wrapper">
  		<div class="fullpage-wp">
  			<div class="campagne">
  				<h2 v-html="$CD('CAMPAGNE_TITRE')" />
  				<p  v-html="$CD('CAMPAGNE_TEXTE')" />
				<p><a href="/MANIFESTE_DE_LA_MODERATON.pdf" target="_blank" style="color:white;"><br />Télécharger le manifeste de campagne</a></p>
  				<div class="frame-campagne">
  					<youtube video-id="DJqve0z-vMM"></youtube>
  				</div>
  				<br />
  				<br />
  			</div>
  			<div class="carousel">
				<carousel 
					:loop="false"
					:responsiveClass="true"
					:startPosition="1"
					:responsive="{0:{items:1 }, 500:{ items:2  }, 900:{ items:2  }, 1000:{  items: 3 }, 10000:{  items: 3 }}"
					:center="true"
					@drag="dragging = true"
					@dragged="stopDrag"
				>
					<div class="img-container">
						<img @click="downloadPdf('/pdf/GASTRONOMANIAC.pdf')" src="/img/profils/cards-gastromaniac.jpg">
					</div>
					<div class="img-container">
						<img @click="downloadPdf('/pdf/BECSUCRE.pdf')" src="/img/profils/cards-bec-sucre.jpg">
					</div>
					<div class="img-container">
						<img @click="downloadPdf('/pdf/APERITIVORE.pdf')" src="/img/profils/cards-aperitivore.jpg">
					</div>
					<div class="img-container">
						<img @click="downloadPdf('/pdf/STREETLOVER.pdf')" src="/img/profils/cards-street-lover.jpg">
					</div>
					<div class="img-container">
						<img @click="downloadPdf('/pdf/VEGGIEGOURMAND.pdf')" src="/img/profils/cards-veggie-gourmand.jpg">
					</div>
					<div class="img-container">
						<img @click="downloadPdf('/pdf/TRADITIONISTA.pdf')" src="/img/profils/cards-traditionista.jpg">
					</div>
					<div class="img-container">
						<img @click="downloadPdf('/pdf/BBQMASTER.pdf')" src="/img/profils/cards-bbq-master.jpg">
					</div>
				</carousel>
  			</div>
  			<div class="nav-campagne">
  				<div>
  					<button @click="openSite()">Découvrir les autres actions de prévention de Vin et Société</button>
  					<button class="mobile" @click="downloadPdf('/pdf/Vin_et_Societe_GUIDE__CONSO__RESPONSABLE__MOBILE.pdf')">Télécharger notre guide de la consommation responsable</button>
  					<button class="desktop" @click="downloadPdf('/pdf/Vin_et_Societe_GUIDE__CONSO__RESPONSABLE__.pdf')">Télécharger notre guide de la consommation responsable</button>					  
  					<button @click="downloadPdf('/pdf/DossierdePresse-VinetAlimentation.pdf')">Télécharger le dossier de presse</button>
  				</div>
  			</div>
  			<div class="contact-presse white-bg-p-5" style="color: black;">
					Relations presse : <a style="color: black;" href="mailto:presse.vinsociete@comfluence.fr">presse.vinsociete@comfluence.fr</a>
					<br />
					Twitter : <a style="color: black;" href="http://twitter.com/ValerieFuchsCom" target="_blank">@ValerieFuchsCom</a>
					<br />
					<!-- <router-link style="color: black;" to="/mentions">Mentions légales</router-link> -->
				</div>  			
  			<br />
  			<br />
  		</div>
  	</div>
  </main>
</template>   
<script>
import carousel from 'vue-owl-carousel'

export default {
	components: { carousel },
	data: function(){
		return {
			dragging: false,
		}
	},
	methods : {
		stopDrag() {
			setTimeout(()=>{
				this.dragging = false;
			}, 100)
		},
		openSite() {
           window.open('https://www.vinetsociete.fr/piliers/engagements-responsables')
		},
		downloadPdf(file) {
			console.log("this.dragging", this.dragging)
			if(!this.dragging) {
				window.open(file)
			}
		}
	}
}
</script>
<style lang="scss">
@import "../scss/_variables.scss";

.contact-presse {
	text-align: center;
	color: white;
	font-size: 16px;
	font-family: "acumin-pro", sans-serif;
	font-weight: 500;
	width: 350px;
  margin: auto;
	margin-bottom: 50px;
	margin-top: 20px;

	a {
		color: white;
	}

	@media screen and (max-width: $mobile-width) {
		font-size: 12px;
	}
}

.frame-campagne {
	div {
		width: 88% !important;
		max-width: 690px;
		margin: 0 auto;

		iframe {
			text-align: center;
			//height: 470px !important;



			@media screen and (max-width: $mobile-width) {
				width: 100% !important;
				//height: 180px !important;
			}
		}
	}
}

.cd {
	width: 350px;
}

.owl-nav {
	display: none;
}

.owl-dots {
	margin-top: 30px;
}

.owl-theme .owl-dots .owl-dot span {
	width: 15px !important;
	height: 15px !important;
	background: transparent !important;
	border: solid 3px white;
}

.owl-theme .owl-dots .active span {
	background: white !important;
}

.owl-item {
	img {
		//width: 22vw !important;
	}
}

.img-container {
	padding-left: 20px;
	@media screen and (max-width: $mobile-width) {
		padding-left: 0px;
	}
	padding-right: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 80% !important;
	}
}

.center>.img-container {
	width: 100%;
	padding-left: 0;
}

.carousel {
	margin-top: 50px;
	margin-bottom: 30px;

	img {
		cursor: pointer;
	}
}

.campagne_wrapper {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	justify-content: center;
	background-image: url('../assets/images/fond-18.jpg');
	background-size: cover;
	background-position: 50% 100%;
	background-repeat: no-repeat;
	z-index: 1;
	overflow: hidden;
}

.campagne {
	width: 1000px;
	margin: 0 auto;
	color: white;
	margin-top: 30px;

	@media screen and (max-width: 1100px) {
	    	width: 768px;
	}		

	@media screen and (max-width: $mobile-width) {
		width: 90%;
		margin-top: 20px;
	}

	h2 {
		font-family: $font-extra;
		font-weight: bold;
		font-style: italic;
		width: 100%;
		text-align: center;
		font-size: 60px;
		line-height: 60px;
		margin-bottom: 30px;

		@media screen and (max-width: $mobile-width) {
			font-size: 65px;
			line-height: 64px;
		}
	}

	p {
		width: 100%;
		text-align: center;

		@media screen and (max-width: $mobile-width) {
			font-size: 14px;
			line-height: 16px;
		}
	}
	a {
		text-align: center;
		color: white;
	}
	iframe {
		margin-top: 40px;
		width: 100%;
		height: 390px;
	}
}

.nav-campagne {
	width: 100%;

	div {
		width: 870px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;

			@media screen and (max-width: 1100px) {
					width: 100%;
			}				

		@media screen and (max-width: $mobile-width) {
			display: block;
			width: fit-content;
			margin: 0 auto;
		}
	}

	button {
		font-family: "acumin-pro", sans-serif;
		font-weight: 500;
		position: relative;
		display: block;
		width: 270px !important;
		height: 65px;
		font-size: 15px;
		font-weight: bold;
		line-height: 20px;
		cursor: pointer;
		border-radius: 60px;
		background-color: rgba(0, 0, 0, 0.5);
		color: white;
		text-align: center;
		margin-bottom: 20px;
		initial-letter: 2;
		border: none;
	}

	button:hover {
		background-color: white;
		color: black;
	}
}
</style>
